
import React from "react";
import styled from "styled-components";
import { Card } from "../../components/card";
import { CustomText } from "../../components/text";
import { Link } from "gatsby"
import PrivacyIcon from "../../images/icons/privacy.svg"
import Footer from "../../components/footer/simpleFooter";
import SimpleNavBar from "../../components/navbar/simpleNavBar";


class Privacy extends React.Component {


    render() {
        return (
            <PrivacyContainer>
                <SimpleNavBar/>
                <Card className="privacyBody">
                    <Card display="block" margin="2em auto" width="min-content">
                        <img src={PrivacyIcon} alt="Privacy" width="100px" />
                    </Card>
                    <Card>
                        <CustomText fontMargin="2em auto 0.5em" textAlign="center" fontSize="40px">
                            Skideo Privacy Policy
                        </CustomText>

                        <CustomText fontMargin="0.5em auto" textAlign="center" fontWeight="600" fontSize="18px" fontColor="#1d1d1f">
                            Updated December 28, 2021
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" textAlign="center" fontWeight="normal" fontSize="18px" fontColor="#7d7c83">
                            Have questions? <Link to="/contact">Contact us </Link>
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" textAlign="center" fontWeight="normal" fontSize="18px" fontColor="#1d1d1f">
                            Skideo's Privacy Policy describes how Skideo collects, uses and protects your personal information.
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" textAlign="center" fontWeight="normal" fontSize="18px" fontColor="#1d1d1f">
                            Every feature Skideo builds has the privacy of the user at heart. Skideo agrees privacy is a
                            key part to ones life especially on the internet and we
                            would do anything in our power to ensure that your are protected
                            and that your data would not be used against you.
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" textAlign="center" fontWeight="normal" fontSize="18px" fontColor="#1d1d1f">
                            Transparency and integrity is core in our operations. Trust Skideo has your best interest at heart and would never use your data for anything that endanger you.

                        </CustomText>
                    </Card>

                    <Card>
                        <CustomText fontMargin="1em auto 0.5em">
                            Data we collect
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" fontWeight="normal" fontSize="15px" fontColor="#1d1d1f">
                            Data Skideo collects entails but not limited to: Email, first name, last name,
                            date of birth, educational status, username, gender, telephone number, areas of interest,
                            biography, feedback, and privacy preferences. Other data points may be taking from the user's interraction with the platform. This data is not set in stone and would be made available when it it concrete.
                        </CustomText>
                        <CustomText fontMargin="1em auto 0.5em">
                            How we use the information we have
                        </CustomText>
                        <CustomText fontMargin="0.5em auto" fontWeight="normal" fontSize="15px" fontColor="#1d1d1f">
                            Skideo does not sell your personal data to any third party.
                            This will always be the case. Third party platforms Skideo uses to
                            provide best services to you may use your Skideo user id, and email
                            to identify you and ensure a seamless user experience.
                            <br />
                            <br />
                            Most of the information we have would be used for internal processing to ensure
                            that your are receieving the best service from us in terms of the how useful the platfom appeals to you.
                        </CustomText>

                        <CustomText fontMargin="1em auto 0.5em">
                            Modifications
                        </CustomText>

                        <CustomText fontMargin="0.5em auto" fontWeight="normal" fontSize="15px" fontColor="#1d1d1f">
                            We may update the privacy policy from time to clearly and transparently
                            capture how exactly we ensure your privacy.
                            <br />
                            <br />
                            Should this happen, we would update you update your primary email address.
                        </CustomText>
                        <CustomText fontMargin="1em auto 0.5em">
                            Contact us
                        </CustomText>

                        <CustomText fontMargin="0.5em auto" fontWeight="normal" fontSize="15px" fontColor="#1d1d1f">
                            If we missed something or you have questions, kindly <Link to="/contact">contact us </Link>.
                            We really appreciate the gesture of our customers speaking to us
                        </CustomText>
                    </Card>
                </Card>
                <Footer />
            </PrivacyContainer>
        )

    }

}

export default Privacy

const PrivacyContainer = styled.div`
    .privacyBody{
        max-width: 50%;
        display: block;
        margin: 2em auto;
        padding: 2em;

        a{
            color: #06c;
            transition: all 500ms ease-in-out;
            font-size: 15px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                color: #06f;

            }

        }

        @media only screen and (max-width: 800px) {
            max-width: 90%;
        }
    }

`
