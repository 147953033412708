import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"


class SimpleNavBar extends React.Component {
  render() {
    return (
      <NewNavBarContainer> 
      {/* the above was initially className="resp__bo" */}
        <div className="navstyle">
          <ul>
            <li className="logo">
              <Link className="logo" href="/">
                Skideo
              </Link>
            </li>
            {/* <li className="nonlogo" className="resp__bo">
              <a href="/teacher">Teacher</a>
            </li> */}
            <li>
                <a className="getstarted" href="https://skideo.tv" rel="noreferrer" target="_blank"> Get Started</a>
            </li>
          </ul>
        </div>
      </NewNavBarContainer>
    );
  }
}

export default SimpleNavBar;

const NewNavBarContainer = styled.div`

  .getstarted{
    color: #923d41;
    background-color: #923d4115;
    border-radius: 10px;
  }
  .navstyle {
    /* Safari */
    position: relative;
    top: 0;

    width: 100%;
    position: fixed;
  }

  @media screen and (max-width: 768px) {
    .navstyle li {
      width: 100%;
    }
  }

  .logo {
    color: #5e0d0d !important;
    font-weight: bold !important;
  }

  .logo {
    color: #5e0d0d;
    font-weight: bold;
  }
  .logo:hover {
    background-color: white;
    color: #5e0d0d;
  }

  li {
    font-size: 1.5em;
  }
  li a:hover {
    background-color: #f1edec;
    border-radius: 10px;
  }

  li a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    margin: 0.5em;
  }

  ul {
    list-style-type: none;
    padding: 0;
    background-color: #fffffc;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto 1em;
    border-bottom: #0000001c 1px solid;
  }

  .floatnavbar ul {
    display: flex;
    justify-content: space-around;
  }
`;
